import { Typography, Paper, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../theme";

export default function FeatureVerticalCard(props) {
  const { t } = useTranslation();
  return (
    <Grid
      item
      xs={11}
      sm={6}
      md={2.7}
      // mr={props.mr}
      // style={{ scale }}
      // component={motion.div}
      // ref={targetRef}
      // transition={{ delay: 0.1 }}
    >
      <Paper
        elevation={6}
        sx={{
          borderTop: "5px solid",
          borderColor: props.color,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
          height: "280px",
        }}
      >
        <Box
          style={{
            width: "70px",
            height: "70px",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: props.background,
            filter: props.shadow,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {props.icon}
        </Box>
        <Typography variant="h3" my={2}>
          {t(props.title)}
        </Typography>
        <Typography
          variant="h5"
          mb={2}
          sx={{ color: `${theme.palette.textGray.main}` }}
        >
          {t(props.discription)}
        </Typography>
      </Paper>
    </Grid>
  );
}
