import React, { useState, useRef } from "react";
import { Box, Container, Typography, Button, Fab, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import RechargePriceSection from "./RechargePriceSection";
import Feature from "./FeatureSection";
import FeatureHorizontalCard from "../component/FeatureHorizontalCard";
import theme from "../theme/index";
import CustomerStories from "../component/CustomerStories";
import ContactUs from "../component/ContactUs";
import PaymentDialog from "../component/PaymentDialog";
import Grid from "@mui/material/Grid";
import Backgroundarc from "../Images/backgroundArcs.svg";
import router from "../Images/2nd product png (1).png";
import Image from "../Images/DeviceDetail.png";
import Recharge from "../Images/Recharge.png";
import Google_badge from "../Images/google-badge.png";
import { useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { animateScreen } from "../theme/animate";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function HomePage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const targetRef = useRef();
  const DeviceFeatureRef = useRef();
  const DeviceRechargeRef = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateBuyNow = () => {
    // 👇️ navigate to /
    navigate("/buy-now");
  };

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  console.log(scrollYProgress);

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);

  const scaleDeviceFeature = useTransform(
    scrollYProgress,
    [0.24, 0.33],
    [0, 1.15]
  );

  const scaleDeviceRecharge = useTransform(
    scrollYProgress,
    [0.45, 0.49],
    [0, 1.15]
  );

  return (
    <motion.Box
      display="flex"
      flexDirection="column"
      initial={animateScreen.initial}
      animate={animateScreen.animate}
      exit={animateScreen.exit}
    >
      <Container maxWidth="xl" sx={{ paddingX: 3 }}>
        <Grid container spacing={2} mt={{ xs: 2, md: 3 }}>
          {/* First section */}

          <Grid item sm={12} md={6} style={{ opacity }}>
            <br />
            <Typography variant="h5"> {t("Get initial 45day free")}</Typography>
            <Typography variant="h1" sx={{ lineHeight: 1.4 }}>
              {t("Manage Irrigation at Your Fingertips with ")}
              <Typography
                variant="hb1"
                color={theme.palette.primary.main}
                component="span"
                sx={{ lineHeight: 1.6 }}
              >
                {t("Seed Connect")}
              </Typography>
            </Typography>
            <Typography variant="h3" my={2}>
              {t(
                "And embark on a journey to make autonomous farming the new norm."
              )}
            </Typography>
            <Box my={2}>
              <Button
                variant="contained"
                style={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
                onClick={navigateBuyNow}
                // onClick={handleClickOpen}
              >
                {t("Buy For ₹3999")}
              </Button>
              <Button href="https://play.google.com/store/apps/details?id=com.bnv.seed&hl=en_IN&gl=US">
                <img
                  src={Google_badge}
                  alt="Play store link"
                  // style={{ maxWidth: "100%", height: "auto", width: "500px" }}
                />
              </Button>
            </Box>
            <Typography
              style={{ color: "#78BE20", opacity: 0.21 }}
              sx={{
                fontSize: { xs: "60px", lg: "90px" },
                fontWeight: 700,
                font: "Roboto Serif",
              }}
            >
              {t("CONNECT")}
            </Typography>
          </Grid>

          <Grid item sm={12} md={6}>
            <Box
              sx={{
                textAlign: "center",
                margin: "auto",
                maxWidth: "100%", // Add this line to ensure image doesn't overflow
              }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.5 } }}
              >
                <img
                  src={router}
                  alt="Seed Connect front view"
                  style={{ maxWidth: "100%", height: "auto", width: "450px" }}
                />
              </motion.div>
            </Box>
          </Grid>

          {/* Features section */}
          <Grid item sm={12} md={12} sx={{}}>
            <Feature />
          </Grid>

          {/* MOBILE FEATURES SECTION (MOCKUP FIRST) */}
          <Grid
            container
            mt={{ xs: 7, md: 15 }}
            sx={{ px: 3, justifyContent: "center" }}
          >
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                alignContent: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "80%" },
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `rgba(124, 193, 82, 0.5)`,
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  overflow: "hidden", // Add overflow property
                  maxHeight: "80%", // Limit height to 75% of container height
                }}
              >
                {/* <motion.div
                  ref={DeviceFeatureRef}
                  style={{
                    scale: scaleDeviceFeature,
                    maxHeight: "80%",
                  }}
                > */}
                <img
                  src={Image}
                  alt="SEED home screen"
                  style={{
                    maxWidth: "80%",
                    width: "230px",
                    marginTop: 90,
                  }}
                />
                {/* </motion.div> */}
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: {
                    xs: 25,
                    md: 35,
                  },
                }}
              >
                {t("Streamline your irrigation with Seed's mobile app")}
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.textGray.main }}
                my={1}
              >
                {t(
                  "User-friendly design enables seamless control of all pump functions from a single intuitive interface"
                )}
              </Typography>

              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="Phase Level"
                discription="Monitor supply conditions with precise three-phase level percentages"
              />
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="Pump ON/OFF and Auto start"
                discription="Easy ON and OFF functionality and Automatically start the pump when power is restored"
              />
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="Pump Scheduling"
                discription="Schedule pump ON and OFF at preferred time"
              />
            </Grid>
          </Grid>

          {/* MOBILE FEATURES SECTION (MOCKUP Secound) */}
          <Grid
            container
            mt={{ xs: 7, md: 10 }}
            sx={{ px: 3, justifyContent: "center" }}
          >
            <Grid item sm={12} md={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: {
                    xs: 25,
                    md: 35,
                  },
                }}
              >
                {t("Recharge your Seed devices hassle-free with our app")}
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.textGray.main }}
                my={1}
              >
                {t(
                  "With our built-in SIM card, forget about purchasing or managing one. Simply recharge your Seed Connect device from the app."
                )}
              </Typography>
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="Built-in Simcard"
                discription="Integrated SIM functionality eliminates managing multiple SIM cards for users."
              />
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="Affordable plans"
                discription="Access budget-friendly plans compared to standard SIM cards"
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                alignContent: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "80%" },
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `rgba(124, 193, 82, 0.5)`,
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  overflow: "hidden", // Add overflow property
                  maxHeight: "80%", // Limit height to 75% of container height
                }}
              >
                {/* <motion.div
                  ref={DeviceFeatureRef}
                  style={{
                    scale: scaleDeviceFeature,
                    maxHeight: "80%",
                  }}
                > */}
                <img
                  src={Recharge}
                  alt="SEED home screen"
                  style={{
                    maxWidth: "80%",
                    width: "230px",
                    marginTop: 90,
                  }}
                />
                {/* </motion.div> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            position: "absolute",
            right: 0,
            zIndex: -1,
          }}
        >
          <img src={Backgroundarc} alt="Logo" />
        </div>
        <RechargePriceSection />
        <CustomerStories />
        <ContactUs />
      </Container>

      <PaymentDialog open={open} handleClose={handleClose}></PaymentDialog>

      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </motion.Box>
  );
}
