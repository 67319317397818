import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import theme from "../theme/index";
import { useTranslation } from "react-i18next";

function PriceCard(props) {
  const { t } = useTranslation();
  return (
    <Grid
      item
      xs={11}
      sm={6}
      md={2.5}
      // component={motion.div}
      // ref={targetRef}
      // style={{ scale }}
    >
      <Paper
        elevation={6}
        my={3}
        sx={{
          backgroundColor: "fff",
        }}
        style={{
          borderTop: props.borderTopColor,
          boxShadow: props.shadow,
          borderRadius: "30px",
          textAlign: "center",
          padding: "30px",
          height: "220px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" my={1}>
          {t(props.title)}
        </Typography>
        <Typography variant="h2" color={props.offerColor}>
          {t(props.offer)}
        </Typography>
        <Typography variant="h5" color="#8E989F">
          {t("Per Month")}
        </Typography>

        <Typography variant="h5" color="#8E989F" my={3}>
          {t(props.description)}
        </Typography>

        {/* <Button
        style={{
          border: props.isTrue ? props.borderDetails : "none",
          backgroundColor: props.bg,
          color: props.dye,
          fontSize: "15px",
          padding: "5px 50px",
          borderRadius: "40px",
          fontWeight: "400",
          height: "38px",
          marginTop: "20px",
        }}
      >
        Buy Now
      </Button> */}
      </Paper>
    </Grid>
  );
}

export default PriceCard;
