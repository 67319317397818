import {
  Box,
  Container,
  Typography,
  Button,
  Tab,
  Tabs,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Divider,
  Select,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { TabContext, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import theme from "../theme/index";
import DeviceCard from "../component/DeviceCard";
import Grid from "@mui/material/Grid";
import ComplaintRegisteredImage from "../Images/ComplaintDone.png";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import { motion } from "framer-motion";
import { animateScreen } from "../theme/animate";

const ComplaintForm = () => {
  const { t } = useTranslation();

  const [selectedCard, setSelectedCard] = useState(null);

  const [complaintFor, setComplaintFor] = React.useState("");

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId);
  };

  const handleComplaintFor = (event) => {
    setComplaintFor(event.target.value);
  };

  const [showComplaintRegistered, setShowComplaintRegistered] = useState(false);

  const handleSubmit = () => {
    setShowComplaintRegistered(true);
  };

  const initialValues = {
    complaint: "",
  };

  const validationSchema = yup.object().shape({
    complaint: yup.string().required(t("Complaint is required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handlesubmitForm(values);
    },
  });

  const handlesubmitForm = async (formData) => {
    console.log("formData", formData);
    try {
      const response = await axios.post(
        "http://localhost:3000/customer-service",
        formData
      );
      console.log(response.data);
      // Handle successful submission (e.g., show success message to user)
    } catch (error) {
      console.log("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div>
      {!showComplaintRegistered && (
        <Container maxWidth="sm">
          {/* Complaint Section */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            formik={formik}
          >
            <Typography variant="h2">{t("Complaint")}</Typography>

            <Box
              variant="h5"
              sx={{
                color: theme.palette.textGray.main3,
                margin: "10px",
              }}
            >
              {t("Complaint for")}
            </Box>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ color: theme.palette.textGray.main4 }}
              >
                {t("Complaint For")}
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={complaintFor}
                label="Complaint For"
                onChange={handleComplaintFor}
              >
                <MenuItem value={"Android_App"}>{"Android App"}</MenuItem>
                <MenuItem value={"Device"}>{"Device"}</MenuItem>
              </Select>
            </FormControl>

            <Box
              variant="h5"
              sx={{
                color: theme.palette.textGray.main3,
                margin: "10px",
              }}
            >
              {t("Select Device")}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "5%",
                marginBottom: "5%",
                justifyContent: "space-between",
              }}
            >
              <DeviceCard
                onSelect={() => handleCardSelect(1)}
                selected={selectedCard === 1}
              />
              <DeviceCard
                onSelect={() => handleCardSelect(2)}
                selected={selectedCard === 2}
              />
              <DeviceCard
                onSelect={() => handleCardSelect(3)}
                selected={selectedCard === 3}
              />
            </Box>

            <Box
              variant="h5"
              sx={{
                color: theme.palette.textGray.main3,
                margin: "10px",
              }}
            >
              {t("Complaint")}
            </Box>
            <TextField
              fullWidth
              id="complaint"
              label="Describe the Complaint"
              sx={{ backgroundColor: theme.palette.bgWhite.main }}
              variant="outlined"
              mb={3}
              onChange={formik.handleChange}
              value={formik.values.complaint}
              error={
                formik.touched.complaint && Boolean(formik.errors.complaint)
              }
              helperText={formik.touched.complaint && formik.errors.complaint}
            />

            <Box>
              <FormControlLabel control={<Checkbox defaultChecked />} />
              <Typography variant="h7">{"I accept the "}</Typography>
              <Typography
                variant="h7"
                sx={{ color: theme.palette.primary.main }}
              >
                {"terms"}
              </Typography>
              <Typography variant="h7">{" and "}</Typography>
              <Typography
                variant="h7"
                sx={{ color: theme.palette.primary.main }}
              >
                {"privacy policy"}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginTop: "30px",
                }}
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                onClick={handleSubmit}
              >
                {t("Submit")}
              </Button>
            </Box>
          </Box>
        </Container>
      )}

      {showComplaintRegistered && <ComplaintRegistered />}
    </div>
  );
};

const ClaimWarrantyForm = () => {
  const { t } = useTranslation();

  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId);
  };

  const [showComplaintRegistered, setShowComplaintRegistered] = useState(false);

  const handleSubmit = () => {
    setShowComplaintRegistered(true);
  };

  const initialValues = {
    complaint_reason: "",
  };

  const validationSchema = yup.object().shape({
    complaint_reason: yup.string().required(t("Complaint Reason is required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handlesubmitForm(values);
    },
  });

  const handlesubmitForm = async (formData) => {
    console.log("formData", formData);
    try {
      const response = await axios.post(
        "http://localhost:3000/customer-service",
        formData
      );
      console.log(response.data);
      // Handle successful submission (e.g., show success message to user)
    } catch (error) {
      console.log("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div>
      {!showComplaintRegistered && (
        <Container maxWidth="sm">
          {/* Complaint Section */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            formik={formik}
          >
            <Typography variant="h2">{t("Claim Warranty")}</Typography>

            <Box
              variant="h5"
              sx={{
                color: theme.palette.textGray.main3,
                margin: "10px",
              }}
            >
              {t("Select Device")}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "5%",
                marginBottom: "5%",
                justifyContent: "space-between",
              }}
            >
              <DeviceCard
                onSelect={() => handleCardSelect(1)}
                selected={selectedCard === 1}
              />
              <DeviceCard
                onSelect={() => handleCardSelect(2)}
                selected={selectedCard === 2}
              />
              <DeviceCard
                onSelect={() => handleCardSelect(3)}
                selected={selectedCard === 3}
              />
            </Box>

            <InputLabel
              id="datepicker"
              sx={{ color: theme.palette.textGray.main3 }}
              shrink={false}
            >
              {"Date of Manufacturing"}
            </InputLabel>
            <TextField
              fullWidth
              type="date"
              id="dateOfManufacturing"
              name="datepicker"
              sx={{}}
            />

            <Box
              variant="h5"
              sx={{
                color: theme.palette.textGray.main3,
                margin: "10px",
              }}
            >
              {t("Reason")}
            </Box>
            <TextField
              fullWidth
              id="complaint_reason"
              label="Reason"
              sx={{ backgroundColor: theme.palette.bgWhite.main }}
              variant="outlined"
              mb={3}
              onChange={formik.handleChange}
              value={formik.values.complaint_reason}
              error={
                formik.touched.complaint_reason &&
                Boolean(formik.errors.complaint_reason)
              }
              helperText={
                formik.touched.complaint_reason &&
                formik.errors.complaint_reason
              }
            />

            <Box>
              <FormControlLabel control={<Checkbox defaultChecked />} />
              <Typography variant="h7">{"I accept the "}</Typography>
              <Typography
                variant="h7"
                sx={{ color: theme.palette.primary.main }}
              >
                {"terms"}
              </Typography>
              <Typography variant="h7">{" and "}</Typography>
              <Typography
                variant="h7"
                sx={{ color: theme.palette.primary.main }}
              >
                {"privacy policy"}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginTop: "30px",
                }}
                onClick={handleSubmit}
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
              >
                {t("Submit")}
              </Button>
            </Box>
          </Box>
        </Container>
      )}

      {showComplaintRegistered && <ComplaintRegistered />}
    </div>
  );
};

const ComplaintRegistered = () => {
  const [showTrackDetails, setShowTrackDetails] = useState(false);

  const handleTrackNowButton = () => {
    setShowTrackDetails(true);
  };

  return (
    <div>
      {!showTrackDetails && (
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "20%",
            }}
          >
            <img
              src={ComplaintRegisteredImage}
              alt="Complaint Registered Image"
            />
            <Typography
              variant="hb2"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "28px",
                margin: "3%",
              }}
            >
              {"Your Complaint is registered"}
            </Typography>

            <Typography
              variant="hb2"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "22px",
                margin: "3%",
              }}
            >
              {"Complaint Id: 129382934"}
            </Typography>

            <Typography
              variant="hb2"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#8C8C8C",
                margin: "3%",
              }}
            >
              {"Thank You for improving the product experience"}
              <br />
              {"We will address the issue as soon as possible "}
            </Typography>

            <Button variant="contained" onClick={handleTrackNowButton}>
              {"Track Now"}
            </Button>
          </Box>
        </Container>
      )}

      {showTrackDetails && <TrackDetails />}
    </div>
  );
};

const ComplaintRegForm = () => {
  const { t } = useTranslation();
  const [sendOtpButton, setSendOtpButton] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");
  const [showForm, setShowForm] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleVerifyNumberClick = () => {
    setShowForm(true);
  };

  const renderSelectedForm = () => {
    switch (selectedValue) {
      case "Complaint":
        return <ComplaintForm />;
      case "Warranty_Claim":
        return <ClaimWarrantyForm />;
      default:
        return null;
    }
  };

  const initialValues = {
    phone_no: "",
    otp: "",
  };

  const validationSchema = yup.object().shape({
    phone_no: yup.string().required(t("Phone Number is required")),
    otp: yup.string().min(4).required(t("OTP is required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handlesubmitForm(values);
    },
  });

  const handlesubmitForm = async (formData) => {
    console.log("formData", formData);
    try {
      const response = await axios.post(
        "http://localhost:3000/customer-service",
        formData
      );
      console.log(response.data);
      // Handle successful submission (e.g., show success message to user)
    } catch (error) {
      alert("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div>
      {!showForm && (
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            formik={formik}
          >
            {/* Type of Request block with select tab */}
            <Typography
              variant="h7"
              sx={{
                color: theme.palette.textGray.main3,
              }}
            >
              {t("Type of Request")}
            </Typography>

            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ color: theme.palette.textGray.main4 }}
              >
                {"Type of Reqest"}
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Type of Request"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                <MenuItem value={"Complaint"}>{"Complaint"}</MenuItem>
                <MenuItem value={"Warranty_Claim"}>{"Warranty Claim"}</MenuItem>
              </Select>
            </FormControl>

            {/* Phone number block */}
            <Typography
              variant="h7"
              mt={2}
              sx={{
                color: theme.palette.textGray.main3,
              }}
            >
              {t("Phone Number")}
            </Typography>
            <TextField
              fullWidth
              id="phone_no"
              label="Phone Number"
              InputLabelProps={{
                style: { color: theme.palette.textGray.main4 },
              }}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.phone_no}
              error={formik.touched.phone_no && Boolean(formik.errors.phone_no)}
              helperText={formik.touched.phone_no && formik.errors.phone_no}
            />

            {/* Button position need to be changed */}
            <Box
              sx={{
                position: "absolute",
                top: "400px",
                right: "450px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginTop: "30px",
                  borderRadius: "50px",
                }}
                onClick={() => {
                  setSendOtpButton(true);
                }}
              >
                <KeyboardDoubleArrowRightRoundedIcon />
              </Button>
            </Box>

            {/* Enter OTP Field */}
            <Typography
              variant="h7"
              mt={2}
              sx={{
                color: theme.palette.textGray.main3,
              }}
            >
              {t("Enter OTP")}
            </Typography>
            <TextField
              fullWidth
              id="otp"
              label="Enter OTP"
              InputLabelProps={{
                style: { color: theme.palette.textGray.main4 },
              }}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.otp}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
            />

            {/* Verify Number Button Field */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginTop: "30px",
                }}
                disabled={!sendOtpButton}
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                onClick={handleVerifyNumberClick}
              >
                {t("Verify Number")}
              </Button>
            </Box>
          </Box>
        </Container>
      )}

      {showForm && renderSelectedForm()}
    </div>
  );
};

const TrackDetails = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // STeps for vertical stepper
  const steps = [
    {
      label: "Claim Requested",
      description: `This is a description`,
    },
    {
      label: "Claim Approved/Rejected",
      description: "This is a description",
    },
    {
      label: "Complete",
      description: `This is a description`,
    },
  ];

  // Details for details section in track device field
  const details = [
    { label: "Complaint id", value: "John Doe" },
    { label: "Device Id", value: "1223232" },
    { label: "Customer Name", value: "John Doe" },
    { label: "Phone Number", value: "+91 8787546753" },
    { label: "Date of Purchase", value: "12 Jan 2024" },
    { label: "Date of Activation", value: "23 Jan 2024" },
  ];

  return (
    <Container maxWidth="md">
      <Grid container spacing={1}>
        {/* Heading  */}
        <Grid item mt={3} mb={3} sm={12}>
          <div style={{ textAlign: "start" }}>
            <Typography variant="h2">{"Track Warranty/Complaint"}</Typography>
          </div>
        </Grid>

        {/* Details of Tracking Device  */}
        <Grid item sm={12} md={6}>
          <Box
            sx={{
              fontFamily: "Public Sans, sans-serif",
              fontWeight: 500,
              fontSize: "16px",
              margin: "10px 0px",
              lineHeight: "24px",
              color: "#262626",
            }}
          >
            {t("Details")}
          </Box>

          <Box>
            {details.map((detail, index) => (
              <Box key={index} sx={{ display: "flex", gap: "5px" }}>
                <Typography
                  sx={{
                    fontFamily: "Public Sans, sans-serif",
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#8C8C8C",
                    flex: "0.75",
                  }}
                  mt={1}
                >
                  {detail.label}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Public Sans, sans-serif",
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#262626",
                    flex: "2.25",
                  }}
                  mt={1}
                >
                  {detail.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Vertical Stepper Field */}
        <Grid item sm={12} md={6}>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const TrackWarrantyForm = () => {
  const { t } = useTranslation();
  const [sendOtpButton, setSendOtpButton] = useState(false);
  const [showTrackDetails, setShowTrackDetails] = useState(false);

  const handleTrackNowButton = () => {
    setShowTrackDetails(true);
  };

  const initialValues = {
    ref_no: "",
    phone_no: "",
    otp: "",
  };

  const validationSchema = yup.object().shape({
    ref_no: yup.string().required(t("Reference Number is required")),
    phone_no: yup.string().required(t("Phone Number is required")),
    otp: yup.string().min(4).required(t("OTP is required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handlesubmitForm(values);
    },
  });

  const handlesubmitForm = async (formData) => {
    console.log("formData", formData);
    try {
      const response = await axios.post(
        "http://localhost:3000/customer-service",
        formData
      );
      console.log(response.data);
      // Handle successful submission (e.g., show success message to user)
    } catch (error) {
      console.log("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div>
      {!showTrackDetails && (
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            formik={formik}
          >
            {/* Warranty/Reference Number Field */}
            <Typography
              variant="h7"
              sx={{
                color: theme.palette.textGray.main3,
              }}
            >
              {t("Warranty/Reference Number")}
            </Typography>
            <TextField
              fullWidth
              id="ref_no"
              label="Warranty/Reference Number"
              InputLabelProps={{
                style: { color: theme.palette.textGray.main4 },
              }}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.ref_no}
              error={formik.touched.ref_no && Boolean(formik.errors.ref_no)}
              helperText={formik.touched.ref_no && formik.errors.ref_no}
            />

            {/* Phone number block */}
            <Typography
              variant="h7"
              mt={2}
              sx={{
                color: theme.palette.textGray.main3,
              }}
            >
              {t("Phone Number")}
            </Typography>
            <TextField
              fullWidth
              id="phone_no"
              label="Phone Number"
              InputLabelProps={{
                style: { color: theme.palette.textGray.main4 },
              }}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.phone_no}
              error={formik.touched.phone_no && Boolean(formik.errors.phone_no)}
              helperText={formik.touched.phone_no && formik.errors.phone_no}
            />

            {/* Button position need to be changed */}
            <Box
              sx={{
                position: "absolute",
                top: "400px",
                right: "450px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginTop: "30px",
                  borderRadius: "50px",
                }}
                onClick={() => {
                  setSendOtpButton(true);
                }}
              >
                <KeyboardDoubleArrowRightRoundedIcon />
              </Button>
            </Box>

            {/* Enter OTP Field */}
            <Typography
              variant="h7"
              mt={2}
              sx={{
                color: theme.palette.textGray.main3,
              }}
            >
              {t("Enter OTP")}
            </Typography>
            <TextField
              fullWidth
              id="otp"
              label="Enter OTP"
              InputLabelProps={{
                style: { color: theme.palette.textGray.main4 },
              }}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.otp}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
            />

            {/* Track Now Button Field */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginTop: "30px",
                }}
                disabled={!sendOtpButton}
                onClick={handleTrackNowButton}
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
              >
                {t("Track Now")}
              </Button>
            </Box>
          </Box>
        </Container>
      )}

      {showTrackDetails && <TrackDetails />}
    </div>
  );
};

const ComplaintRegTab = () => {
  return (
    <div>
      <ComplaintRegForm />
    </div>
  );
};

const TrackWarrantyTab = () => {
  return (
    <div>
      <TrackWarrantyForm />
    </div>
  );
};

const CustomerService = () => {
  const [tab, setTab] = React.useState("0");

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <motion.Box
      display="flex"
      flexDirection="column"
      initial={animateScreen.initial}
      animate={animateScreen.animate}
      exit={animateScreen.exit}
    >
      <Container maxWidth="xl" sx={{ paddingX: 3 }}>
        <Grid
          container
          spacing={3}
          mt={5}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          {/* Heading  */}
          <div
            style={{ textAlign: "center", marginBottom: "2%", width: "100%" }}
          >
            <Typography
              variant="hb2"
              sx={{ lineHeight: 1.2, textAlign: "center" }}
            >
              {"Online Service and Complaint"}
            </Typography>
          </div>

          {/* Tab Selection */}
          <Box sx={{ width: "90%", bgcolor: "background.paper" }}>
            <TabContext value={tab}>
              <Tabs value={tab} onChange={handleTabChange} centered>
                <Tab
                  value={"0"}
                  style={{ minWidth: "33%" }}
                  label={
                    <Typography style={{ textTransform: "none" }}>
                      {"Complaint Registration"}
                    </Typography>
                  }
                />
                <Tab
                  value={"1"}
                  style={{ minWidth: "33%" }}
                  label={
                    <Typography style={{ textTransform: "none" }}>
                      {"Track Warranty"}
                    </Typography>
                  }
                />
                <Tab
                  value={"2"}
                  style={{ minWidth: "33%" }}
                  label={
                    <Typography style={{ textTransform: "none" }}>
                      {"Terms and Conditions"}
                    </Typography>
                  }
                />
              </Tabs>

              {/* Tab 1 => Complaint Registration */}
              <TabPanel value={"0"}>
                <ComplaintRegTab />
              </TabPanel>

              {/* Track Warranty Tab */}
              <TabPanel value={"1"}>
                <TrackWarrantyTab />
              </TabPanel>

              {/* Terms and Conditions => 3rd Tab */}
              <TabPanel value={"2"}></TabPanel>
            </TabContext>
          </Box>
        </Grid>

        <br />
        <br />

        <Divider
          sx={{ backgroundColor: theme.palette.textGray.main2, height: "2px" }}
        />
      </Container>
    </motion.Box>
  );
};

export default CustomerService;
