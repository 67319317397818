import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import Image from "../Images/product.png";
import theme from "../theme";

const DeviceCard = ({ onSelect, selected }) => {
  return (
    <div
      style={{
        border: `3px groove ${
          selected ? theme.palette.primary.main : "#ffffff"
        }`,
        cursor: "pointer",
        borderRadius: "10%",
      }}
      onClick={onSelect}
    >
      <Card sx={{ maxWidth: 160, borderRadius: "10%" }}>
        <CardMedia component="img" image={Image} alt="product" />
        <CardContent sx={{ textAlign: "center" }}>
          <Typography gutterBottom variant="h5" component="div">
            Seed Connect
          </Typography>
          <Typography variant="body2" color="text.secondary">
            121232
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeviceCard;
