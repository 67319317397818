import React, { useRef } from "react";
import { Grid, Typography, Box } from "@mui/material";
import PriceCard from "../component/PriceCard";
import theme from "../theme/index";
import { useTranslation } from "react-i18next";
import { motion, useScroll, useTransform } from "framer-motion";

function RechargePriceSection() {
  const { t } = useTranslation();
  const targetRef = useRef();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });
  const scale = useTransform(scrollYProgress, [0, 0.1], [0, 1]);
  return (
    <Box
      mt={{ xs: 5, md: 10 }}
      sx={{
        textAlign: "center",
        alignItems: "baseline",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 25,
                md: 35,
              },
            }}
          >
            {t("Affordable Device Plans")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            my={2}
            variant="h3"
            sx={{
              color: theme.palette.textGray.main,
            }}
          >
            {t(
              "Leave worries behind with our budget-conscious plans, tailored to every farmer's needs. No more SIM card purchases and managing hefty monthly plans."
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        mt={1}
        spacing={5}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <PriceCard
          title="36 Month Plan"
          offer="₹89*"
          offerColor="#1A73E9"
          borderTopColor="5px solid #1A73E9"
          button="#FFFFFF"
          borderDetails="2px solid #1A73E9"
          isTrue={true}
          description={
            "*Get a 36-month (3 Year) plan at ₹89/month, totaling ₹3,204 (inclusive of taxes)."
          }
          shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
          dye="#1A73E9"
          bg="#fff"
        />

        <PriceCard
          title="12 Month Plan"
          offer="₹109*"
          offerColor="#7CC152"
          borderTopColor="5px solid #7CC152"
          button="#FFFFFF"
          borderDetails="2px solid #7CC152"
          isTrue={true}
          description={
            "*Get a 12-month (1 Year) plan at ₹109/month, totaling ₹1,308 (inclusive of taxes)."
          }
          shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
          dye="#7CC152"
          bg="#fff"
        />

        <PriceCard
          title="6 Month Plan"
          offer="₹129*"
          offerColor="#8E67BE"
          borderTopColor="5px solid #8E67BE"
          button="#8E67BE"
          isTrue={false}
          description={
            "*Get a 6-month plan at ₹129/month, totaling ₹774 (inclusive of taxes)."
          }
          shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
          dye="#fff"
          bg="#8E67BE"
        />

        <PriceCard
          title="3 Month Plan"
          offer="₹159*"
          offerColor="#FF92B0"
          borderTopColor="5px solid #FF92B0"
          borderDetails="2px solid #FF92B0"
          isTrue={true}
          description={
            "*Get a 3-month plan at ₹159/month, totaling ₹477 (inclusive of taxes)."
          }
          shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
          dye="#FF92B0"
          bg="#fff"
        />
      </Grid>
    </Box>
  );
}

export default RechargePriceSection;
