import React, { useState } from "react";
import { Box, Typography, Fab, useMediaQuery, Grid } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import leftquote from "../Images/left-quote.svg";
import img from "../Images/sachin_jamkar_profile.jpg";
import { TestimonialCard } from "./TestimonialCard";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";

const steps = [
  {
    uName: "Sachin Jamkar",
    imgPath: img,
    rating: 4.5,
    description: `
    पहिले माझ्या शेताचा पंप चालु बंद करणे माझ्यासाठी त्रासदायक होते, विशेषत: रात्री उशिरा जेव्हा मला पंप स्वतः जाऊन चालु बंद करावा लागत होता. पण आता मात्र, सीड मोबाईल ॲपमुळे, मी फक्त एका क्लिकवर कुठूनही पंप सहजपणे चालु बंद करू शकतो. हे उपकरण मला माझ्या शेतीला पाणी देण्याच्या कामात खूप मदत करते.`,
  },
  // {
  //   uName: "Darshan Dodal",
  //   imgPath: img,
  //   description:
  //     "An ad group contains one or more ads which target a shared set of keywords.",
  // },
  // {
  //   uName: "Shankar Patil",
  //   imgPath: img,
  //   description: `Try out different ad text to see what brings in the most customers,
  //             and learn how to enhance your ads using features like ad extensions.
  //             If you run into any problems with your ads, find out how to tell if
  //             they're running and how to resolve approval issues.`,
  // },
];

const CustomerStories = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [startIndex, setStartIndex] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if (!isMobile && startIndex + 2 < maxSteps) {
      setStartIndex((prevIndex) => prevIndex + 1);
    } else if (isMobile && startIndex + 1 < maxSteps) {
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleBack = () => {
    if (startIndex > 0) {
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <Box
        mt={{ xs: 10, md: 15 }}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{ fontWeight: 700 }}
          color={theme.palette.primary.main}
          fontFamily={"Roboto Serif"}
          fontSize={{ md: 45, xs: 35 }}
        >
          {t("Customer Stories")}
        </Typography>
        <Box>
          <Fab
            sx={{ m: 0.5 }}
            disabled={startIndex === 0}
            aria-label="Previous"
            size="small"
            color="primary"
            onClick={handleBack}
          >
            <ArrowCircleUpIcon style={{ rotate: "270deg" }} />
          </Fab>
          <Fab
            sx={{ m: 0.5 }}
            disabled={
              (isMobile && startIndex + 1 >= maxSteps) ||
              (!isMobile && startIndex + 2 >= maxSteps)
            }
            aria-label="Next"
            size="small"
            color="primary"
            onClick={handleNext}
          >
            <ArrowCircleUpIcon style={{ rotate: "90deg" }} />
          </Fab>
        </Box>
      </Box>
      <Grid container mt={2} spacing={2}>
        <Grid item sm={12} md={1}>
          <img height={"73"} src={leftquote} alt="leftquote" />
        </Grid>
        {steps
          .slice(startIndex, isMobile ? startIndex + 1 : startIndex + 2)
          .map((step, index) => (
            <Grid item sm={12} md={5.5} key={index}>
              <TestimonialCard
                discription={step.description}
                uName={step.uName}
                imgPath={step.imgPath}
                rating={step.rating}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default CustomerStories;
