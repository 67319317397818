import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FeatureHorizontalCard from "../component/FeatureHorizontalCard";
import router from "../Images/Farmer.png";
import theme from "../theme/index";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import { animateScreen } from "../theme/animate";

const AboutUsPage = () => {
  const { t } = useTranslation();
  return (
    <motion.Box
      display="flex"
      flexDirection="column"
      initial={animateScreen.initial}
      animate={animateScreen.animate}
      exit={animateScreen.exit}
    >
      <Container maxWidth="xl">
        {/* <Grid container spacing={2} mt={5}>
          <Grid item sm={12} md={12}>
            <Typography variant="h5">12 day free</Typography>
            <Typography variant="h1">
              Ready to launch your app in one the,
            </Typography>
            <Typography variant="hb1" component="span">
              Application
            </Typography>
            <Typography variant="h4">
              Find the best application in the appie.
            </Typography>
          </Grid>
        </Grid> */}
      </Container>
      <Grid item sm={12} md={12}>
        <Box
          sx={{
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              paddingTop: "30px",
              background: `linear-gradient(71deg, #7CC152 48.18%, rgba(124, 193, 82, 0.85) 97.64%)`,
              marginTop: { xs: "60px", sm: "200Px" },
            }}
          >
            <Container maxWidth="xl">
              <img
                src={router}
                alt="router image"
                style={{ marginTop: "-200px", maxWidth: "60%" }}
              />
              <Grid
                item
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "initial",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: 35,
                      md: 45,
                    },
                  }}
                  mt={2}
                  color={theme.palette.bgWhite.main}
                >
                  {t("Empowering Farmers Through Technology")}
                </Typography>
                <Typography
                  variant="h2"
                  mb={10}
                  mt={2}
                  sx={{
                    fontSize: {
                      xs: 20,
                      md: 25,
                    },
                    textAlign: "justify",
                  }}
                  fontWeight={400}
                  color={theme.palette.bgWhite.main}
                >
                  {t(
                    "At Seed, we are dedicated to accelerating the transition of farmers towards autonomous farming practices. Our mission is to provide simple, affordable, and sustainable technology solutions that enable farmers to embrace automation and maximize efficiency. By offering innovative tools and support, we aim to empower farmers to achieve greater productivity and sustainability in their operations. Together, let's pave the way towards a future of autonomous farms, where technology works hand in hand with tradition for the benefit of all."
                  )}
                </Typography>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Grid>
      {/* <Container maxWidth="xl">
        <Grid container mt={7} spacing={1}>
          <Grid item sm={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: 35,
                  md: 45,
                },
              }}
            >
              Automate the water pump with 3.5k Only
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: theme.palette.textGray.main }}
              my={1}
            >
              Lorem ipsum dolor sit amet, consectetur
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #1A73E9 39.1%, #1A73E9 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(26, 115, 233, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #FF92B0 39.1%, #FF92B0 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(255, 146, 176, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #8E67BE 39.1%, rgba(142, 103, 190, 0.75) 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(142, 103, 190, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl"></Container> */}
    </motion.Box>
  );
};

export default AboutUsPage;
