import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import SettingsInputComponentOutlinedIcon from "@mui/icons-material/SettingsInputComponentOutlined";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import AdbIcon from "@mui/icons-material/Adb";
import FeatureVerticalCard from "../component/FeatureVerticalCard";
import TranslateIcon from "@mui/icons-material/Translate";
import theme from "../theme/index";
import { motion, useScroll, useTransform } from "framer-motion";
import { animateScreen } from "../theme/animate";
import Fade from "@mui/material/Fade";

export default function FeatureSection(props) {
  const { t } = useTranslation();
  const targetRef = useRef();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const scale = useTransform(scrollYProgress, [0, 0.1], [0, 1]);
  return (
    <div>
      <Grid container mt={{ xs: 7, md: 10 }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 25,
                md: 35,
              },
            }}
          >
            {t("Modern Features for Modern Farmers")}
          </Typography>
          <Typography
            my={2}
            variant="h2"
            sx={{
              fontSize: {
                xs: 20,
                md: 25,
              },
              color: theme.palette.textGray.main,
            }}
          >
            {t(
              "Simplify Farming, Boost Efficiency, and Lead Agriculture Toward Sustainability with Tailored Features and Easy Operation"
            )}
          </Typography>
        </Grid>
        <Grid
          container
          mb={5}
          mt={1}
          spacing={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // flexDirection: "column",
          }}
        >
          <FeatureVerticalCard
            icon={
              <SettingsInputComponentOutlinedIcon
                style={{ fontSize: "35px", color: "white" }}
              />
            }
            mr={5}
            background="linear-gradient(304deg, #1A73E9 39.1%, rgba(26, 115, 233, 0.75) 83.89%)"
            shadow="drop-shadow(1px 3px 6px rgba(26, 115, 233, 0.50))"
            color="#1A73E9"
            title="Live Phase Report"
            discription="Get updates of 3 phase supply to your pump in real time"
          />

          <FeatureVerticalCard
            icon={
              <SettingsRemoteIcon
                style={{ fontSize: "35px", color: "white" }}
              />
            }
            mr={5}
            color="#78BE20"
            title="Remote ON/OFF"
            shadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
            background="linear-gradient(304deg, #78BE20 39.1%, #7CC152 83.89%)"
            discription="Effortlessly control your pump from anywhere in the world"
          />

          <FeatureVerticalCard
            icon={
              <AccessAlarmsIcon style={{ fontSize: "35px", color: "white" }} />
            }
            mr={5}
            color="#8E67BE"
            title="Time based scheduling"
            shadow="drop-shadow(1px 3px 6px rgba(142, 103, 190, 0.50))"
            background="linear-gradient(304deg, #8E67BE 39.1%, #8E67BE 83.89%)"
            discription="Set time-based schedules to conveniently control your pump"
          />

          <FeatureVerticalCard
            icon={
              <TranslateIcon style={{ fontSize: "35px", color: "white" }} />
            }
            mr={0}
            color="#FF92B0"
            title="Multi Lingual"
            shadow="drop-shadow(1px 3px 6px rgba(255, 146, 176, 0.50))"
            background="linear-gradient(304deg, #FF92B0 39.1%, #FF92B0 83.89%)"
            discription="Support for English, Hindi, and Marathi, with additional languages on the way"
          />
        </Grid>
      </Grid>
    </div>
  );
}
