import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import HomePage from "./view/HomePage";
import AboutUsPage from "./view/AboutUsPage";
import BuyNowPage from "./view/BuyNowPage";
import CustomerService from "./view/CustomerService";
import PrivacyPage from "./view/PrivacyPolicy";
import ContactUs from "./component/ContactUs";
import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about us" element={<AboutUsPage />} />
        <Route path="/buy-now" element={<BuyNowPage />} />
        <Route path="/customer-service" element={<CustomerService />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
