import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import theme from "../theme";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().min(3).required("Name is required"),
    message: yup.string().required("Message is required"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email Address is required"),
    subject: yup.string().min(3).required("Subject is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handlesubmitForm(values);

      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handlesubmitForm = async (formData) => {
    console.log("formData", formData);
    try {
      const response = await axios.post(
        "http://localhost:3001/contact",
        formData
      );
      console.log(response.data);
      // Handle successful submission (e.g., show success message to user)
    } catch (error) {
      alert("Error submitting form:", error);
      console.error("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };
  return (
    <div>
      <Grid container spacing={2} mt={{ xs: 10, md: 20 }}>
        <Grid item sm={12} md={6}>
          {/* <img src={logo} alt="logo" /> */}
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 25,
                md: 35,
              },
            }}
          >
            {t("Reach Us")}
          </Typography>
          {/* <Typography variant="hb2">{t("Reach Us")}</Typography> */}
          {/* <Box> */}
          <Typography variant="h4" mt={2}>
            {t("Bits and Volts Pvt. Ltd.,")}
            <br />
            {t("Sushila Complex, Near Gunvantrao Deshmukh School")}
            <br />
            {t("Pusad, Yavatmal, Maharashtra -445204")}
          </Typography>
          <Typography
            mt={1}
            variant="h4"
            style={{
              color: `${theme.palette.textGray.main}`,
            }}
          >
            <a
              href="mailto:support@bitsandvolts.in"
              style={{ color: `${theme.palette.textGray.main}` }}
            >
              support@bitsandvolts.in
            </a>
          </Typography>
          <Typography
            variant="h4"
            mt={1}
            style={{
              color: `${theme.palette.textGray.main}`,
            }}
          >
            +91-9359526445
          </Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15006.252654030028!2d77.5489061!3d19.9006595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd10b9a306bd13f%3A0x43531ae4e00f357b!2sSushila%20complex!5e0!3m2!1sen!2sin!4v1708087691068!5m2!1sen!2sin"
            width="100%"
            height="275"
            style={{ border: "0", marginTop: "20px" }}
            loading="lazy"
          ></iframe>
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15006.252654030028!2d77.5489061!3d19.9006595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd10b9a306bd13f%3A0x43531ae4e00f357b!2sSushila%20complex!5e0!3m2!1sen!2sin!4v1708087691068!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </Grid>
        <Grid item sm={12} md={6} mt={{ xs: 2, md: 0 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 25,
                md: 35,
              },
            }}
          >
            {t("Get In Touch")}
          </Typography>
          {/* <Typography variant="hb2">{t("Get In Touch")}</Typography> */}
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  label={t("Name")}
                  sx={{ backgroundColor: theme.palette.bgWhite.main }}
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  label={t("Email")}
                  sx={{ backgroundColor: theme.palette.bgWhite.main }}
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              style={{
                marginTop: "20px",
              }}
              id="subject"
              label={t("Subject")}
              variant="outlined"
              sx={{ backgroundColor: theme.palette.bgWhite.main }}
              onChange={formik.handleChange}
              value={formik.values.subject}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
            />
            <TextField
              fullWidth
              multiline
              style={{
                marginTop: "20px",
              }}
              id="message"
              label={t("Message")}
              sx={{ backgroundColor: theme.palette.bgWhite.main }}
              variant="outlined"
              rows={5}
              onChange={formik.handleChange}
              value={formik.values.message}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#78BE20",
                marginTop: "30px",
                borderRadius: "50px",
              }}
            >
              {t("Send Message")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
